import garantiasAdminApi from "../garantias_admin.api";
import utils from "@/helpers/utilidades";

export default {

  index(filtros = {},paginado = true) {
    return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/campos_dinamicos`, filtros, paginado));
  }

  ,obtener(campo) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/campos_dinamicos/${campo}`);
  },

  crear(payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/campos_dinamicos`, payload)
  },

  editar(campo, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/campos_dinamicos/${campo}`, payload)
  },

  eliminar(campo) {
    return garantiasAdminApi.api.delete(`${garantiasAdminApi.baseURL}/campos_dinamicos/${campo}`)
  },

}